body,
html {
  width: 100%;
  height: 100%;
}

body {
  /*font-family: 'Alegreya Sans', sans-serif;*/
  font-weight: 400;
  font-size: 18px;
  background-color: #fcf9f9;

}

h1,
h2,
h4,
h5,
h6 {
  font-weight: 700;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
}

.text-center {
  text-align: center;
}

.text-italic {
  font-style: italic;
}

.float-right {
  float: right;
}

.btn {
  font-size: 1em;
}

#root > div {
  position: relative;
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
  background-color: #fcf9f9;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 8em);
  left: calc(50% - 8em);
  width: 14em;
  height: 14em;
  background-image: url(./img/What-Women-Say-Round.png);
  background-size: cover;
  transition: all 400ms ease;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 3s;
}

.button {
  display: inline-block;
  padding: 1.05rem 1.25rem;
  white-space: pre-wrap;
  border-radius: 2px;
  background-color: #f0bb13;
  border: #fff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: transform .8s;
}

.button:hover {
  border: #fff;
  color: #fff;
  text-decoration: none;
  transform: scale(1.15);
}

.bmc-button img{
  height: 34px !important;
  width: 35px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
}
.bmc-button span {
  margin-left: 15px;
  font-size: 19px !important;
}
.bmc-button{
  padding: 7px 10px 7px 10px !important;
  line-height: 35px !important;
  height:51px !important;
  min-width:217px !important;
  text-decoration: none !important;
  display:inline-flex !important;
  color:#ffffff !important;
  background-color:#5F7FFF !important;
  border-radius: 5px !important;
  border: 1px solid transparent !important;
  padding: 7px 10px 7px 10px !important;
  font-size: 20px !important;
  letter-spacing:-0.08px !important;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5) !important;
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  margin: 0 auto !important;font-family:'Lato', sans-serif !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  -o-transition: 0.3s all linear !important;
  -webkit-transition: 0.3s all linear !important;
  -moz-transition: 0.3s all linear !important;
  -ms-transition: 0.3s all linear !important;
  transition: 0.3s all linear !important;
}
.bmc-button:hover, 
.bmc-button:active, 
.bmc-button:focus {
  -webkit-box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5) !important;
  opacity: 0.85 !important;
  color:#ffffff !important;
  transform: scale(1.1);
}

/* ---------------------------------------------- */
/* Header */
/* ---------------------------------------------- */

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  /*width: 100%;*/
  height: 5em;

  padding-right: 1.5em;
  padding-left: 1.5em;

  /*background-color: #fdfcfc;*/
  /*background-color: #fff;
  border-bottom: 0.08375em solid #ebe1e1;*/
}

.header a {
  color: #064264;
  /*margin-right: 1em;*/
  transition: all 200ms ease-in-out;
}

.header a:hover {
  color: #eeba1c;
  /*border-bottom: 3px solid #eeba1c;*/
}

.header .active {
  color: #eeba1c;
}

.header-logo {
  color: #fff;
  font-size: 2em;
  font-weight: 700;
}

.header-popup {
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.head .masthead {
  min-height: 5rem;
  padding-top: 8rem;
  padding-bottom: 3rem;
}

.head a h1 {
  color: #000;
}

.head a:hover {
  text-decoration: none;
}

.header .nav-toggler {
  z-index: 1001;
  color: #064264;
  opacity: 1;
  cursor: pointer;
  transition: 200ms;
}

@media (max-width: 991px) {
  .header {
    height: 2.7em;
  }

  .header .nav-toggler {
    margin-top: 0px;
    margin-left: 0px;
  }

  .header .open {
    font-size: 1em;
  }

  .header .close {
    font-size: 1.25em;
  }
}

@media (min-width: 992px) {
  .header .nav-toggler {
    margin-left: 15px;
  }

  .header .open {
    font-size: 1.2em;
  }

  .header .close {
    font-size: 1.45em;
  }
}

.header .close {
  color: #fff;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #064264;
  overflow-x: hidden;
  transition: 0.8s;
  padding-top: 120px;
}

.sidenav a {
  padding: 8px 8px 25px 40px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: all 200ms ease-in-out;
}

.sidenav a:hover {
  color: #eeba1c;
}

@media (min-width: 992px) {
  .header-popup {
    font-size: 1.5em;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header-popup {
    font-size: 1.2em;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .header-popup {
    font-size: 1em;
    letter-spacing: 1px;
  }
}

@media (max-width: 499px) {
  .header {
    padding-left: .5em;
  }
  .header-popup {
    font-size: .8em;
    letter-spacing: 1px;
  }
}

/* ---------------------------------------------- */
/* Main */
/* ---------------------------------------------- */

.masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 8rem;
  transition: 300ms all ease;
  background-color: transparent;
}

.masthead h1 {
  font-size: 3rem;
  font-family: 'Monoton', cursive;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2.5px;
}

/*.masthead img {
  max-width: 75%;
}*/

@media (min-width: 992px) {
  .masthead img {
    max-width: 75%;
  }

  .masthead h1 {
    font-size: 4.4rem;
  }

  .subtitle {
    padding-bottom: 50px;
  }

  .subtitle h2 {
    font-size: 2em;
    line-height: 1.4;
  }

  .subtitle h3 {
    font-size: 1.15rem;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .masthead img {
    max-width: 75%;
  }

  .subtitle {
    width: 70%;
    margin: 40px auto;
  }

  .subtitle h2 {
    font-size: 1.25rem;
  }

  .subtitle h3 {
    font-size: 1.2rem;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .masthead img {
    max-width: 80%;
  }

  .subtitle {
    width: 70%;
    margin: 30px auto;
  }

  .subtitle h2 {
    font-size: 1.25rem;
  }

  .subtitle h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 499px) {
  .masthead img {
    max-width: 80%;
  }

  .subtitle {
    width: 70%;
    margin: 20px auto;
  }

  .subtitle h2 {
    font-size: .8rem;
  }

  .subtitle h3 {
    font-size: .7rem;
  }
}

.subtitle h2,
.subtitle h3 {
  /*font-family: 'Lora', serif;*/
  /*font-family: 'Cormorant Garamond', serif;*/
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  
}

.subtitle h2 {
  text-align: center;
  color: #064264;
}

.subtitle h3 {
  text-align: left;
  margin: 30px auto;
}

/* ---------------------------------------------- */
/* Static Page */
/* ---------------------------------------------- */

@media (max-width: 900px) {
  .page > div {
    width: 90%;
  }

  .page h1 {
    font-size: 2.6em;
  }
}

@media (min-width: 901px) {
  .page > div {
    width: 70%;
  }

  .page h1 {
    font-size: 3em;
  }
}

.page > div {
  margin: 0 auto;
  padding-top: 20px;
}

.page hr {
  max-width: 70.5rem;
  height: 0;
  border-top: 0;
  border-bottom: 1px solid #7f7f7f;
  margin: 3.5em auto 1em auto;
  clear: both;
}

.page h1,
.page h2,
.page h3 {
  font-family: 'Montserrat', sans-serif;
  color: #064264;
  
}

.page h1 {
  font-weight: 500;
  margin-bottom: 80px;
  text-align: center;
}

.page h2 {
  font-size: 1.95em;
  font-weight: 600;
}

.page h3 {
  font-size: 1.4em;
  font-weight: 300;
}

.page h2,
.page h3 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}

.page p {
  /*font-family: 'Lora', serif;*/
  font-weight: 400;
  font-size: 22px;
  margin: 16px auto;
  color: #064264;
}

.page .simple-link {
  color: #000;
}

.page .simple-link:hover {
  text-decoration: none;
}

.custom-link {
  border-color: #f0bb13;
  text-decoration: none;
  border-bottom-width: 0.15625em;
  border-bottom-style: solid;
  padding-bottom: 1px;
  padding-top: 2px;
  transition: color .3s ease-out, background .3s ease-in;
  color: #064264;
}

.custom-link:hover {
  color: #fff;
  background-color: #f0bb13;
}

.page button {
  display: block;
  margin: 0 auto;
}

.page .button-wrapper {
  text-align: left;
  padding-bottom: 40px;
}

.page .button-wrapper {
  text-align: center;
  padding-bottom: 40px;
}

.page .img-section {
  padding-bottom: 60px;
  overflow: auto;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .page .img-container {
    width: 100%;
    height: 380px;
    margin-bottom: 5%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

@media (min-width: 768px) {
  .page .img-container {
    width: 42%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .page .img-container:nth-child(odd) {
    margin-right: 8%;
    float: left;
  }

  .page .img-container:nth-child(even) {
    float: right;
  }
}

.page .damiano {
  background-image: url(./img/Damiano-Fossa.jpg);
  transition: all .3s ease;
}

.page .damiano:hover {
  background-position: 50%;
}

.page .veronica {
  background-image: url(./img/Veronica-Fossa.jpg);
  transition: all .3s ease;
}

.page .veronica:hover {
  background-position: 80%;
}

.page .not-found {
  font-size: 55px;
  font-weight: 700;
  width: 180px;
  height: 180px;
  line-height: 170px;
  text-align: center;
  border: 7px solid #064264;
  color: #064264;
  border-radius: 50%;
  margin: 0 auto 60px auto;
}

.page .title-not-found {
  color: #064264;
}

/* ---------------------------------------------- */
/* Quotes and Categories */
/* ---------------------------------------------- */

.category-nav-wrapper {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.category-item {
  width: auto;
  margin: 12px auto;
  padding: 0;
}

.category-item button {
  padding: .5rem .6rem;
  white-space: pre-wrap;
  border-radius: 2px;
  z-index: 50;
  text-transform: uppercase;
  letter-spacing: .7px;
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.category-item button:hover {
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

@media (min-width: 1200px) {
  .category-nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-row-gap: 15px;
    grid-column-gap: 0;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-template-rows: auto;
    grid-row-gap: 15px;
    grid-column-gap: 8px;
  }

  .card {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .category-nav {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 0px;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 5px;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .category-nav {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 0px;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 5px;
  }

  .card {
    max-height: 800px !important;
  }
}

@media (max-width: 499px) {
  .category-nav {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 0px;
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 5px;
  }

  .card {
    width: 100%;
  }
}

.card > a {
  color: #fff;
}

.card > a:hover {
  text-decoration: none;
}

.card {
  cursor: pointer;
  border: none;
  border-radius: 2px;
  /*max-width: 370px;*/
  max-height: 300px;
  margin: 10px auto;
  padding: 25px 10px;
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.card:hover {
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.single {
  padding-bottom: 80px;
}

.single .card {
  max-height: 100%;
}

@media (min-width: 1200px) {
  .go-back-container,
  .single .card {
    width: 50%;
  }

  .single #quote {
    font-size: 1.8em;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .go-back-container,
  .single .card {
    width: 80%;
  }

  .single #quote {
    font-size: 1.4em;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .single {
    padding-bottom: 150px !important;
  }

  .go-back-container,
  .single .card {
    width: 80%;
  }

  .single #quote {
    font-size: 1.5em;
  }

  .footer {
    position: relative !important;
  }
}

@media (max-width: 499px) {
  .head .masthead {
    padding-top: 5em;
    padding-bottom: 0;
  }

  .single {
    padding-bottom: 200px !important;
  }

  .go-back-container,
  .single .card {
    width: 80%;
  }

  .single #quote {
    font-size: 1.5em;
  }

  .footer {
    margin-top: 3em;
    position: relative !important;
    line-height: 30px !important;
  }
}

.go-back-container,
.single .card {
  cursor: text;
  margin: 15px auto;
  /*background: none;*/
  -webkit-transition: none;
  transition: none;
  transition: none;
  transition: none;
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
}

.go-back-link {
  color: #064264;
  font-size: 0.8625em;
}

.go-back-link:hover {
  color: #000;
  text-decoration: none;
}

.single .card:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

#copy-wrapper {
  display: inline;
  margin-left: 25px;
}

#copy-wrapper svg {
  margin-right: 15px;
}

#quote {
  font-size: 1.3em;
  font-family: 'DM Serif Display', serif;
  font-weight: 300;
  letter-spacing: 0.01225em;
}

.single #quote {
  margin-top: 15px;
  margin-bottom: 50px;
  letter-spacing: 0.03225em;
}

/*.single #quote:before {
  content: "“";
  font-family: 'Monoton', cursive;
  position: absolute;
  font-size: 3em;
  top: 0.78em;
  line-height: 1;
}*/

.single #category {
  margin-bottom: 50px;
  font-size: .9em;
  text-align: right;
  letter-spacing: .7px;
}

.single #quote,
.single #category,
.single #author,
.single a,
.single #copy-wrapper {
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.single #author-link {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

.single #author-link h4 {
  border-color: #fff;
  text-decoration: none;
  border-bottom-width: 0.05625em;
  border-bottom-style: solid;
  padding-bottom: 1px;
  padding-top: 2px;
  letter-spacing: 0.01225em;
}

.single #author-link:hover h4 {
  text-decoration: none;
  opacity: .75;
}

@media (max-width: 320px) {
  .single #author {
    font-size: .8em;
  }  
}

@media (min-width: 321px) and (max-width: 499px) {
  .single #author {
    font-size: 1.05em;
  }  
}

@media (min-width: 500px) {
  .single #author {
    font-size: 1.2em;
  }
}

.single .twitter {
  color: #000;
}

#quotation-marks {
  font-family: 'Monoton', cursive;
  font-weight: 400;
  font-size: 2rem;
}

.single .animated {
  display: inline;
}

#author {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: .9em;
  font-weight: 600;
  float: right;
  margin-top: .5em;
  letter-spacing: 0.01225em;
}

#category {
  text-transform: lowercase;
  letter-spacing: .7px;
  font-size: .8em;
  margin-bottom: .95em;
  font-weight: 600;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* ---------------------------------------------- */
/* Footer */
/* ---------------------------------------------- */

.footer {
  bottom: 0;
  width: 100%;
  height: 100px;
  line-height: 60px;
}

.footer p {
  color: #064264;
}

@media (max-width: 499px) {
  .footer p {
    font-size: .7em;
  }
}

@media (max-width: 550px) {
  .footer {
    width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 500px) {
  .footer p {
    font-size: .8em;
  }
}

.footer a {
  color: #064264;
  text-decoration: none;
  transition: all 200ms ease-in-out;
}

/*.footer a:hover {
  color: #4392A6;
}*/